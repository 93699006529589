<template>
  <div class="nav-v">
    <v-app-bar
      eagle-header
      app
      fixed
      clipped-left
      elevation="0"
      class="d-print-none mainNav "
    >
      <div class="d-flex justify-center align-center logoBox linerp-green">
        <v-img contain width="25" height="25" :src="siteLogoUrl"></v-img>
      </div>

      <v-toolbar-title class="d-flex pa-2 align-center" @click="toIndex">
        <span class="pa-2">{{ providerInfo.name }}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <div class="text-center">
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              x-large
              color="transparent"
              v-bind="attrs"
              v-on="on"
              class="m-button"
            >
              商品分類
              <i class="ri-arrow-drop-down-fill"></i>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="{ name, id } in filteredProductLists"
              :key="id"
              link
              class="self-v-list-item"
              :class="{ 'v-list-item--active': publicMenuId === id }"
              ><router-link
                :to="`/store.linerp.co/product-lists/${providerId}/${id}`"
                class="d-flex justify-space-between link-a"
              >
                {{ name }}
              </router-link>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

      <!-- 這個原本是用消費者登入用的 只是目前還沒有進行這一塊地開發 -->
      <!-- <v-divider vertical class="mx-2" inset></v-divider>
      <v-btn x-large>供應商介紹</v-btn>
      <v-divider vertical class="mx-2" inset></v-divider>
      <v-btn @click="toLogin" x-large>登入</v-btn> -->
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    categories: [],
    providerId: "",
    providerInfo: {},
  }),
  computed: {
    ...mapGetters({
      siteLogoUrl: "base/siteLogoUrl",
    }),
    publicMenuId() {
      return this.$store.getters["publicProvider/publicMenuId"];
    },
    filteredProductLists() {
      return this.categories.filter((item) => item.productItem != undefined);
    },
  },
  created() {
    this.getProviderInfoAPI();
    this.getProductApi();
    this.providerId = this.$route.params.providerId;
  },
  methods: {
    toLogin() {
      this.$router.push({
        name: "front-provider-login",
      });
    },
    toProviderProductLists(id) {
      this.$store.dispatch("publicProvider/setPublicMenuId", id);
      if (this.$route.name !== "front-provider-product-lists") {
        this.$router.push({
          name: "front-provider-product-lists",
        });
      }
    },
    toIndex() {
      this.$router.push({
        name: "front-provider-index",
      });
    },
    productItemincategories(categories, productList) {
      if (productList) {
        productList.forEach((product) => {
          product.menus.forEach((menuId) => {
            let category = categories.find(
              (category) => category.id === menuId
            );
            if (category) {
              if (!category["productItem"]) {
                category["productItem"] = [];
              }
              category["productItem"].push(product);
            }
          });
        });
        this.categories = categories;
      } else {
        console.error("productList or productList.menus is undefined");
      }
    },
    async getProductApi() {
      this.$store.dispatch("loading/active");
      try {
        const productList = await this.$api.collection.publicApi.getProductIndex(
          this.$route.params.providerId
        );
        const categories = await this.$api.collection.publicApi.getProductMenu(
          this.$route.params.providerId
        );
        this.productItemincategories(categories.data, productList.data);
      } catch (err) {
        console.error(err);
      } finally {
        this.$store.dispatch("loading/close");
      }
    },
    async getProviderInfoAPI() {
      const getProviderInfo = await this.$api.collection.publicApi.getProviderInfo(
        this.$route.params.providerId
      );
      this.providerInfo = getProviderInfo;
    },
  },
};
</script>

<style lang="sass">
.m-button
  padding: 0 10px!important

.mainNav
  .v-toolbar__content
    padding: 0 16px 0 0
    .logoBox
      width: 64px
      height: 64px
      &.linerp-blue
        background: #006DE0
      &.linerp-green
        background: #004737

      @media screen and (max-width:960px)
        width: 56px
        height: 56px

.v-toolbar__title
  cursor: pointer !important
.v-btn--is-elevated
  box-shadow: none !important
  .theme--light.v-btn:hover::before
    opacity: 0 !important
.v-list-item.self-v-list-item
  min-height: 40px
  &:last-child
    border-bottom: none
  .v-list-item__title
    text-align: center !important
.ri-arrow-drop-down-fill
      font-size:24px
.router-link-exact-active
  border:none

.nav-v .link-a
  border: none !important

.v-application a
  color: black
</style>
